<template>
  <div>
    <v-toolbar v-if="!isMobile" tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title v-if="!loadPage && book">{{
        book.title
      }}</v-toolbar-title>
    </v-toolbar>
    <v-container v-if="!loadPage && book">
      <v-row>
        <v-col class="text-left" :cols="!isMobile ? 12 : ''">
          <v-card
            v-if="
              +book.paid &&
              ((currentUser && !currentUser.vip && !book.hasBook) ||
                !currentUser)
            "
            class="loyalty-action mb-4"
            :class="isMobile ? 'mb-8': ''"
          >

            <v-card-text class="text-center">
              Купите эту книгу и получите доступ к книгам со
              специальной скидкой для вас!
            </v-card-text>
          </v-card>

          <v-card
            class="book-big-card"
            :dark="isMobile"
            :class="isMobile ? 'mobile-book-card' : ''"
          >
            <div
              class="cover"
              :style="{ backgroundImage: 'url(' + book.image_big + ')' }"
            ></div>

            <div class="book-long">
              <div class="book-image">
                <!-- <v-img v-if="book.image_big" :src="book.image_big"></v-img> -->
                <img v-if="book.image_big" :src="book.image_big" />
              </div>
              <div class="book-long-body">
                <v-row>
                  <v-col cols="12" sm="8">
                    <div class="book-title">{{ book.title }}</div>
                    <div class="book-user-name">
                      <router-link
                        :to="{
                          name: 'UserShow',
                          params: { userName: user.name },
                        }"
                        >{{ user.public_name }}</router-link
                      >
                    </div>
                    <div class="book-user-name">
                      <span
                        v-if="book.coauthors && book.coauthors.length > 0"
                        class="book-coauthors"
                      >
                        <span
                          class="coauthor"
                          v-for="(item, index) in book.coauthors"
                          :key="index"
                        >
                          <router-link
                            :to="{
                              name: 'UserShow',
                              params: { userName: item.name },
                            }"
                            >{{ item.public_name }}</router-link
                          >{{ book.coauthors.length > index + 1 ? ", " : "" }}
                        </span>
                      </span>
                    </div>

                    <book-tags
                      v-if="!isMobile && tags.length > 0"
                      :tags="tags"
                    ></book-tags>
                    <!-- <div v-if="!isMobile && tags.length > 0" class="book-tags">
                      Теги:
                      <router-link
                        v-for="(item, index) in tags"
                        :key="item.id"
                        :item="item"
                        :index="index"
                        class="mx-1"
                        :to="{
                          name: 'TagShow',
                          params: { slug: item.slug.ru },
                        }"
                        >#{{ item.name.ru }}</router-link
                      >
                    </div> -->
                    <div
                      v-if="!isMobile && categories.length > 0"
                      class="book-categories"
                    >
                      Жанры:
                      <v-breadcrumbs
                        light
                        :items="categories"
                        divider=" "
                      ></v-breadcrumbs>
                    </div>
                    <book-info
                      :info="{
                        count_likes: likes_count,
                        count_libraries: libraries_count,
                        count_comments: book.count_comments,
                      }"
                    ></book-info>
                    <book-statistics :book="book"></book-statistics>
                    <div
                      v-if="admin"
                      class="book-status"
                      :class="+book.status ? 'success--text' : 'error--text'"
                    >
                      {{ +book.status ? "опубликована" : "черновик" }}
                    </div>
                    <!-- <div class="book-price" v-if="book.price">Цена: {{ book.price }} руб.</div> -->
                  </v-col>
                  <v-col v-if="!isMobile" cols="12" sm="4">
                    <book-top-info :book="book"></book-top-info>
                  </v-col>
                </v-row>
              </div>
            </div>
            <v-card-actions class="mt-auto pl-3 pr-3 pb-3 pt-0">
              <!-- <v-btn
                v-if="!isMobile"
                :to="{name: 'ReaderShow', params: {bookSlug: book.slug}}"
                color="primary"
                dark
              >Читать</v-btn> -->
              <v-btn
                v-if="!isMobile && (book.hasBook || book.chpid)"
                :to="{ name: 'ReaderShow', params: { bookSlug: book.slug } }"
                color="primary"
                dark
                >{{ book.hasBook ? "Читать" : "Читать фрагмент" }} <v-icon small right>$vuetify.icons.headphones</v-icon></v-btn
              >
              <v-btn
                v-if="!isMobile && !book.hasBook"
                @click="showBuyModal"
                class="buy-green"
                dark
                :loading="buyLoading"
                v-html="priceText"
              ></v-btn>
              <v-btn
                v-if="!isMobile && book.hasBook && +book.paid && !admin"
                class="bought-green"
              >
                <v-icon left>$vuetify.icons.purse</v-icon> Куплено</v-btn
              >

              <!-- Для админа продажи книги-->
              <v-btn
                v-if="!isMobile && book.hasBook && +book.paid && admin"
                class="bought-green"
              >
                <v-icon left>$vuetify.icons.purse</v-icon>
                {{ adminPriceText }}</v-btn
              >
              <v-btn
                v-if="!isMobile && book.hasBook && !+book.paid && admin"
                class="bought-green"
                :to="{
                  name: 'CabinetBookEdit',
                  params: { id: book.id },
                  query: { tab: 'edit' },
                  hash: '#sale-book',
                }"
              >
                <v-icon left>$vuetify.icons.purse</v-icon> Продавать
                книгу</v-btn
              >

              <v-btn
                v-if="!isMobile && user.donat && !book.paid"
                @click="getDonatLink"
                color="primary"
                dark
                >Поддержать автора</v-btn
              >
              <v-spacer v-if="!isMobile"></v-spacer>
              <v-btn
                small
                v-if="admin && !isMobile"
                :to="{
                  name: 'CabinetBookEdit',
                  params: { id: book.id },
                  query: { tab: 'edit' },
                  hash: '#status',
                }"
                text
                tile
                >{{
                  +book.status ? "Снять с публикации" : "Опубликовать"
                }}</v-btn
              >
              <v-btn
                v-if="currentUser && admin"
                :to="{ name: 'CabinetBookEdit', params: { id: book.id } }"
                small
                fab
                light
              >
                <v-icon>$vuetify.icons.square_edit_outline</v-icon>
              </v-btn>

              <like table_type="book" :table="book"></like>
              <library table_type="book" :table="book" class="ml-2"></library>

              <right-menu table_type="book" :table="book"></right-menu>
            </v-card-actions>
          </v-card>

          <v-card
            v-if="isMobile && categories.length > 0"
            class="mobile-categories my-4"
          >
            <div class="book-categories-mobile pa-4">
              <v-breadcrumbs
                light
                :items="categories"
                divider=" "
              ></v-breadcrumbs>
            </div>
          </v-card>
          <v-card v-if="isMobile && tags.length > 0" class="mobile-tags my-4">
            <book-tags class="book-tags-mobile pa-4" :tags="tags"></book-tags>

            <!-- <div class="book-tags-mobile pa-4">
              <router-link
                v-for="(item, index) in tags"
                :key="item.id"
                :item="item"
                :index="index"
                class="mx-1"
                color="primary"
                :to="{ name: 'TagShow', params: { slug: item.slug.ru } }"
                >#{{ item.name.ru }}</router-link
              >
            </div> -->
          </v-card>

          <!-- <v-btn
            v-if="isMobile"
            :to="{name: 'ReaderShow', params: {bookSlug: book.slug}}"
            block
            color="primary"
            dark
          >Читать</v-btn> -->
          <v-card
            v-if="isMobile && !admin"
            class="mobile-book-subscribe my-4 pa-4"
          >
            <div
              class="subscribe-long d-flex flex-no-wrap justify-start"
              :class="$vuetify.breakpoint.xsOnly ? 'mobile' : ''"
            >
              <div class="subscribe-image">
                <router-link
                  :to="{
                    name: 'UserShow',
                    params: { userName: user.name },
                  }"
                >
                  <img :src="user.avatar_url" />
                </router-link>
              </div>
              <div class="subscribe-long-body">
                <div class="subscribe-long-body-left">
                  <router-link
                    :to="{
                      name: 'UserShow',
                      params: { userName: user.name },
                    }"
                    >{{ user.public_name }}</router-link
                  >
                </div>
                <div class="subscribe-long-body-right">
                  <v-btn
                    @click="subscribe"
                    :color="inSubscribe ? 'green darken-3' : 'primary'"
                    dark
                    block
                    :loading="subscribeLoading"
                    >{{ inSubscribe ? "Подписаны" : "Подписаться" }}</v-btn
                  >
                </div>
              </div>
            </div>
          </v-card>
          <v-btn
            v-if="isMobile && (book.hasBook || book.chpid)"
            :to="{ name: 'ReaderShow', params: { bookSlug: book.slug } }"
            block
            color="primary"
            dark
            >
            
            {{ book.hasBook ? "Читать" : "Читать фрагмент" }} <v-icon small right>$vuetify.icons.headphones</v-icon></v-btn
          >
          <v-btn
            class="mt-4 buy-green"
            v-if="isMobile && !book.hasBook"
            @click="showBuyModal"
            block
            dark
            :loading="buyLoading"
            v-html="priceText"
          ></v-btn>
          <v-btn
            v-if="isMobile && book.hasBook && +book.paid && !admin"
            class="mt-4 bought-green"
            block
          >
            <v-icon left>$vuetify.icons.purse</v-icon> Куплено</v-btn
          >
          <!-- Для админа -->
          <v-btn
            v-if="isMobile && book.hasBook && +book.paid && admin"
            class="mt-4 bought-green"
            block
          >
            <v-icon left>$vuetify.icons.purse</v-icon>
            {{ adminPriceText }}</v-btn
          >
          <v-btn
            v-if="isMobile && book.hasBook && !+book.paid && admin"
            class="bought-green mt-4"
            block
            :to="{
              name: 'CabinetBookEdit',
              params: { id: book.id },
              query: { tab: 'edit' },
              hash: '#sale-book',
            }"
          >
            <v-icon left>$vuetify.icons.purse</v-icon> Продавать книгу</v-btn
          >

          <v-btn
            class="mt-4"
            v-if="isMobile && user.donat && !book.paid"
            @click="getDonatLink"
            color="primary"
            block
            dark
            >Поддержать автора</v-btn
          >
          <v-card class="book-tabs mt-4">
            <v-tabs v-model="tab" background-color="primary" dark>
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#tab-annotation">Аннотация</v-tab>
              <!-- <v-tab href="#tab-awards">Награды</v-tab> -->
              <v-tab href="#tab-contents">Содержание</v-tab>
              <v-tab v-if="book.book_trailer" href="#tab-yt">Буктрейлер</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-annotation">
                <v-card flat>
                  <div
                    class="book-tab-text"
                    v-html="
                      book.excerpt_formating
                        ? book.excerpt_formating
                        : 'Данных нет'
                    "
                  ></div>
                </v-card>
              </v-tab-item>

              <v-tab-item value="tab-awards">
                <v-card flat>
                  <div class="book-tab-text">Тут будут награды</div>
                </v-card>
              </v-tab-item>

              <v-tab-item value="tab-contents">
                <v-card class="book-content" flat>
                  <v-list v-if="book.chapters_show.length > 0">
                    <v-list-item-group color="primary">
                      <v-list-item
                        v-for="item in book.chapters_show"
                        :key="item.id"
                        :to="{
                          name: 'ReaderShowFull',
                          params: { bookSlug: book.slug, id: item.id },
                        }"
                        text
                        tile
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.title"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <div v-else class="book-tab-text">Данных нет</div>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab-yt">
                <v-card class="book-yt pa-4 text-center" flat>
                  <youtube
                    :video-id="book.book_trailer"
                    :player-width="ytSixze.width"
                    :player-height="ytSixze.height"
                    :player-vars="{ start: 0, autoplay: 0, rel: 0 }"
                  ></youtube>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>

          <comments
            v-if="book && +book.can_comment"
            :commentable_id="bookId"
            commentable_type="book"
            :commentable_slug="book.slug"
            :entity_user_id="book.user_id"
            :route_name="$route.name"
          ></comments>
        </v-col>
      </v-row>

      <v-dialog v-model="showBuy" class="text-center" max-width="400">
        <v-card class="px-4">
          <v-card-title class="headline justify-center">Покупка</v-card-title>

          <div class="buy-block text-center">
            <v-btn
              large
              @click="getBuyLink"
              class="buy-green"
              dark
              :loading="buyLoading"
              >{{ +book.finish ? "Купить" : "Подписка" }}
              {{ book.final_amount }} руб.</v-btn
            >
            <p>(Чтение на сайте)</p>
          </div>
          <div class="promo-block text-center mx-8 mt-4 pa-4">
            <h5>Использовать промокод (при наличии)</h5>
            <v-form v-model="validPromo" ref="formPromo">
              <v-text-field
                v-model="promoCode"
                name="inputPromoCode"
                label="Промокод"
                required
                :rules="promoRules"
              ></v-text-field>
              <v-btn
                @click="sendPromo"
                class=""
                color="primary"
                dark
                :loading="buyLoading"
                small
                >Отправить</v-btn
              >
            </v-form>
          </div>

          <v-card-actions class="justify-space-around">
            <v-spacer></v-spacer>
            <v-btn color="red darken-1 " text @click="showBuy = false"
              >закрыть</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <vip-dialog ref="vipDialog"></vip-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BookInfo from "./BookInfo.vue";
import BookStatistics from "./BookStatistics.vue";
import RightMenu from "../secondmenu/RightMenu.vue";
import Like from "../secondmenu/Like.vue";
import Library from "../secondmenu/Library.vue";
import Comments from "../comment/Comments.vue";
import BookTopInfo from "./BookTopInfo.vue";
import BookTags from "./BookTags.vue";
import VipDialog from "./VipDialog.vue";
export default {
  components: {
    BookInfo,
    BookTopInfo,
    BookStatistics,
    RightMenu,
    Comments,
    Like,
    Library,
    BookTags,
    VipDialog,
  },
  data: () => ({
    valid: true,
    order: null, //Нужно для проверки платежа
    sort: "new",
    sortings: [
      { text: "TOP", val: "top" },
      { text: "По новизне", val: "new" },
      { text: "По популярности", val: "popular" },
      { text: "По комментариям", val: "comments" },
    ],
    page: 1,
    tab: null,
    buyLoading: false,
    showBuy: false,
    validPromo: true,
    promoCode: "",
    promoRules: [
      (v) => !!v || "Поле обязательно",
      (v) => v.length >= 8 || "Минимум 8 символов",
    ],
    subscribeLoading: false,
  }),
  metaInfo() {
    if (this.book) {
      return {
        title: `${this.book.title}: все книги читать онлайн бесплатно - Bookstab, Букстаб`,
        meta: [
          {
            name: "description",
            content: this.metaDescr,
          },
          {
            property: "og:title",
            content:
              this.book.title +
              ": все книги читать онлайн бесплатно - Bookstab, Букстаб",
          },
          { property: "og:site_name", content: "Bookstab" },
          {
            property: "og:description",
            content: this.metaDescr,
          },
          { property: "og:type", content: "book" },
          { property: "og:url", content: window.location.href },
          { property: "og:image", content: this.book.image_big },
        ],
      };
    }
    return {};
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    validatePromo() {
      return this.$refs.formPromo.validate();
    },
    getDonatLink() {
      if (this.user.donat && this.user.donat != "null") {
        window.open(this.user.donat, "_blank");
      }
    },
    showBuyModal() {
      if (!this.currentUser) {
        this.$router.push({
          name: "Login",
          //   params: { slug: this.bookSlug },
          query: { return_url: this.$route.fullPath },
        });
        return;
      }
      this.showBuy = true;
    },
    getBuyLink() {
      if (!this.currentUser) {
        this.$router.push({
          name: "Login",
          //   params: { slug: this.bookSlug },
          query: { return_url: this.$route.fullPath },
        });
        return;
      }
      let app = this;
      app.buyLoading = true;
      this.$store
        .dispatch("getBookBuyLink", {
          book_id: app.bookId,
          namespace: "book",
        })
        .then((response) => {
          app.buyLoading = false;
          if (response.data.linkPay) {
            window.location.href = response.data.linkPay;
          }
        })
        .catch(() => {
          // ym(XXXXXX, 'reachGoal', 'TARGET_NAME');
          // app.$metrika.reachGoal('buy_book_b454');
          app.buyLoading = false;
        });
    },
    getBook() {
      let app = this;
      this.$store
        .dispatch("getBook", {
          bookSlug: app.bookSlug,
          page: app.page,
          sort: app.sort,
          order: app.order,
        })
        .then((response) => {
          if (response.data.buyGoal === true) {
            this.$metrika.reachGoal("buy_book_454");
          }

          if (response.data.showVip === true) {
            this.$refs.vipDialog.open();
          }

          // console.log('buyGoal',response.data.buyGoal);
        });
    },
    sendPromo() {
      if (!this.validatePromo()) return;

      let app = this;
      app.buyLoading = true;
      this.$store
        .dispatch("cabinet_promo/sendPromo", {
          promoCode: app.promoCode,
          book_id: app.book.id,
        })
        .then(() => {
          app.$router.go(0);
          app.buyLoading = false;
        })
        .catch(() => {
          app.buyLoading = false;
        });
    },
    subscribe() {
      if (!this.currentUser) {
        this.$root.showAuthMessage();
        return;
      }
      var app = this;
      app.subscribeLoading = true;
      this.$store
        .dispatch("user/subscribeUser", {
          user_id: app.user.id,
          sort: app.sort,
        })
        .then(() => {
          app.subscribeLoading = false;
        })
        .catch(() => {
          app.subscribeLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      siteSettings: "siteSettings",
      book: "book",
      likes_count: "likes/likesCount",
      libraries_count: "libraries/librariesCount",
      subscribers: "user/subscribers",
    }),
    user() {
      return this.book.user;
    },
    tags() {
      let tags = this.book && this.book.tags ? this.book.tags : [];
      if (this.isMobile && tags.length > 3) {
        //Если мобильный обрезаем до трех
        return tags.slice(0, 3);
      }
      return tags;
    },
    categories() {
      let categories = this.book.categories_show.map(function (category) {
        category.text = category.name;
        category.to = { name: "CategoryShow", params: { slug: category.slug } };
        return category;
      });
      // console.log(categories);
      // if (this.isMobile && categories.length > 3) {
      //   //Если мобильный обрезаем до трех
      //   return categories.slice(0, 3);
      // }
      return categories;
    },
    bookTitle() {
      return this.book.bookTitle;
    },
    bookId() {
      return this.book.id;
    },
    admin() {
      if (
        this.currentUser &&
        this.book &&
        this.currentUser.id == this.book.user_id
      ) {
        return true;
      }
      return false;
    },
    metaDescr() {
      if (this.book && this.book.excerpt_formating) {
        let size = 160;
        let newText = this.book.excerpt_formating;
        if (this.book.excerpt_formating.length > size) {
          newText = this.book.excerpt_formating.slice(0, size) + " ...";
        }
        return newText;
      }
      return "Bookstab Читать книги полностью по сериям бесплатно онлайн.";
    },
    ytSixze() {
      let size = { width: 768, height: 432 };
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          size = {
            width: "100%",
            height: (this.$vuetify.breakpoint.width - 56) / 1.77,
          };
          break;
        case "sm":
          size = { width: 520, height: 293 };
          break;
        case "mdAndUp":
          size = { width: 768, height: 432 };
          break;
      }
      return size;
    },
    priceText() {
      let text = "";
      let pre = "Подписка";
      if (this.book) {
        if (+this.book.finish) {
          pre = "Купить";
        }

        if (+this.book.final_amount < +this.book.price) {
          text =
            pre +
            ' <span class="old">' +
            this.book.price +
            "</span> " +
            this.book.final_amount +
            " руб.";
        } else {
          text = pre + " " + this.book.price + " руб.";
        }
      }

      return text;
    },
    adminPriceText() {
      let text = "";
      let pre = "Подписка";
      if (this.book) {
        if (+this.book.finish) {
          pre = "Продается";
        }

        if (+this.book.final_amount < +this.book.price) {
          text =
            pre +
            ' <span class="old">' +
            this.book.price +
            "</span> " +
            this.book.final_amount +
            " руб.";
        } else {
          text = pre + " " + this.book.price + " руб.";
        }
      }

      return text;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    inSubscribe() {
      if (
        this.currentUser &&
        this.subscribers &&
        this.subscribers.indexOf(this.currentUser.id) >= 0
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    // siteSettings() {
    //   this.updateData();
    // },
  },
  mounted() {
    // this.updateData();
    this.bookSlug = this.$route.params.slug ? this.$route.params.slug : "";
    this.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
    this.sort = this.$route.query.sort ? this.$route.query.sort : "new";
    this.order = this.$route.query.order ? this.$route.query.order : null;
    this.getBook();
  },
  created() {},
  beforeDestroy() {},
};
</script>
