<template>
  <div>
    <v-btn
      @click="selectLibrary"
      small
      :color="inLibrary ? 'green darken-3' : ''"
      :light="!inLibrary"
      :dark="inLibrary"
      :loading="libraryLoading"
      fab
    >
      <v-icon>$vuetify.icons.bookshelf</v-icon>
    </v-btn>

    <!-- <v-dialog v-model="libraryModal" class="text-center" max-width="290">
      <v-card>

        <libcats @select="selectLibcat"></libcats>

        <v-card-actions class="justify-space-around">
          <v-btn color="green darken-1" text @click="libraryModal = false"
            >Закрыть</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <libcats-dialog ref="libraryModal" @select="selectLibcat"></libcats-dialog>

  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import Libcats from "../account/Libcats.vue";
import LibcatsDialog from "../account/LibcatsDialog.vue";
export default {
  components: { LibcatsDialog },
  props: ["table", "table_type"],
  data: () => ({
    libraryLoading: false,
    loading: false,
    libraryModal: false,
  }),

  methods: {
    selectLibrary() {
      if (this.table_type == "book") {
        if (this.inLibrary) {
          this.toggleLibrary();
        } else {
          this.$refs.libraryModal.open();
          // this.libraryModal = true;
        }
      } else {
        this.toggleLibrary();
      }
    },
    toggleLibrary(libcatId = null) {
      if (!this.currentUser) {
        this.$root.showAuthMessage();
        return;
      }
      let app = this;
      app.libraryLoading = true;
      this.$store
        .dispatch("libraries/toggleLibrary", {
          tableId: app.table.id,
          tableType: app.table_type,
          libcatId: libcatId,
        })
        .then(() => {
          app.libraryLoading = false;
        })
        .catch(() => {
          app.libraryLoading = false;
        });
    },
    selectLibcat(id) {
      // console.log(id);
      if(!this.libraryLoading){
        this.toggleLibrary(id);
      }
      // this.libraryModal = false;
      this.$refs.libraryModal.close();
      
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      libraries: "libraries/libraries",
      librariesIds: "libraries/librariesIds",
      libraries_count: "libraries/librariesCount",
    }),
    inLibrary() {
      if (
        this.currentUser &&
        this.librariesIds.indexOf(this.currentUser.id) >= 0
      ) {
        return true;
      }
      return false;
    },
    libraryButtonText() {
      if (this.inLibrary) {
        return "Удалить из библиотеки";
      }
      return "Добавить в библиотеку";
    },
  },
  watch: {},
  mounted() {},
};
</script>
