<template>
  <div v-if="book && book.top_cat" class="book-top-info">
 
    <h4>Текущий рейтинг:</h4>

    <div
      class="one-top-info"
      v-for="(item, index) in book.top_cat"
      :key="index"
    >
      #{{ +item.number ? +item.number : "?"}} в 
      <router-link :to="{ name: 'CategoryShow', params: { slug: item.slug } }"
        >{{ item.name }}</router-link
      >
    </div>
  </div>
</template>
<script>
export default {
  props: ["book"],
};
</script>