<template>
  <div
    v-if="tags.length > 0"
    class="book-tags"
    :class="isMobile ? 'mobile' : ''"
  >
    <span class="tag-head">Теги:</span>
    <router-link
      v-for="(item, index) in tags"
      :key="item.id"
      :item="item"
      :index="index"
      class="mx-1"
      :to="{ name: 'TagShow', params: { slug: item.slug.ru } }"
      >#{{ item.name.ru }}</router-link
    >
  </div>
</template>
<script>
export default {
  props: ["tags"],
  data() {
    return {};
  },
  methods: {},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>
