<template>
<v-dialog v-model="libraryModal" class="text-center" max-width="290">
      <v-card>
        <!-- <v-card-title class="headline justify-center">Вы</v-card-title> -->

        <libcats @select="selectLibcat"></libcats>

        <v-card-actions class="justify-space-around">
          <v-btn color="green darken-1" text @click="close"
            >Закрыть</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import Libcats from "./Libcats.vue";
export default {
  // props: ["libraryModal"],
  components: {
    Libcats,
  },
  data: () => ({
    libraryModal: false,
  }),
  methods: {
    selectLibcat(id) {
      this.$emit("select", id);
    },
    open(){
      this.libraryModal = true;
    },
    close(){
      this.libraryModal = false;
    }
  },
};
</script>