<template>
  <v-dialog v-model="showDialog" class="text-center" max-width="600">
    <v-card>
      <v-card-title class="headline justify-center">Поздравляем!</v-card-title>

      <v-card-text class="text-center">
        <div>
          Поздравляем! Вам присвоен VIP-статус и теперь вы можете приобретать лучшие книги со скидкой в течение 3-х дней.
        </div>
        <v-btn
          color="green darken-1"
          class="mt-8"
          text
          :to="{
            name: 'CategoryShow',
            params: { slug: 'all' },
            query: { sort: 'loyalty' },
          }"
          >Перейти VIP-Каталог
        </v-btn>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="red darken-1" text @click="close">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
    <script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data: () => ({
    showDialog: false,
  }),
  methods: {
    close() {
      this.showDialog = false;
    },
    open() {
      this.showDialog = true;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      siteSettings: "siteSettings",
    }),
    isAuthor() {
      if (this.currentUser && this.currentUser.author) {
        return true;
      }
      return false;
    },
  },
  watch: {},
  mounted() {},
};
</script>