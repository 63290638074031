<template>
  <v-btn
    @click="addLike"
    small
    :color="inLikes ? 'green darken-3' : ''"
    :light="!inLikes"
    :dark="inLikes"
    :loading="likeLoading"
    fab
  >
    <v-icon>$vuetify.icons.star</v-icon>
  </v-btn>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: ["table", "table_type"],
  data: () => ({
    likeLoading: false,
  }),

  methods: {
    addLike() {
      if (!this.currentUser) {
        this.$root.showAuthMessage();
        return;
      }
      let app = this;
      app.likeLoading = true;
      this.$store
        .dispatch("likes/addLike", {
          tableId: app.table.id,
          tableType: app.table_type,
        })
        .then(() => {
          app.likeLoading = false;
        })
        .catch(() => {
          app.likeLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      likes: "likes/likes",
      likesIds: "likes/likesIds",
      likes_count: "likes/likesCount",
    }),
    inLikes() {
      if (this.currentUser && this.likesIds.indexOf(this.currentUser.id) >= 0) {
        return true;
      }
      return false;
    },
    likeButtonText() {
      if (this.inLikes) {
        return "Удалить нравится";
      }
      return "Нравится";
    },
  },
  watch: {},
  mounted() {},
};
</script>
