<template>
  <div>
    <v-menu left bottom offset-y>
      <template v-slot:activator="{ on }">
        <v-btn class="ml-2" v-on="on" small light fab>
          <v-icon>$vuetify.icons.dots_horizontal</v-icon>
        </v-btn>
      </template>

      <v-list>
        <!-- <v-list-item link>
                    <v-list-item-title>Поделиться</v-list-item-title>
                  </v-list-item>-->
        <v-list-item link>
          <v-list-item-title @click="dialogReport = true"
            >Пожаловаться</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog v-model="dialogReport" class="text-center" max-width="640">
      <v-card>
        <v-card-title class="headline justify-center">Жалоба</v-card-title>
        <v-form v-if="currentUser" v-model="valid" ref="formReport">
          <v-card-text>
            <v-select
              v-if="reportTypes && reportTypes.length"
              v-model="reportType"
              :items="reportTypes"
              item-text="title"
              item-value="id"
              label="Причина"
            ></v-select>
            <v-textarea
              name="reportMsg"
              label="Текст жалобы"
              value
              v-model="reportMsg"
              :rules="reportMsgRules"
            ></v-textarea>
          </v-card-text>
        </v-form>
        <v-card-text class="text-center" v-else>
          Вы должны
          <v-btn
            :to="{ name: 'Login', query: { return_url: $route.fullPath } }"
            tile
            link
            text
            >авторизоваться</v-btn
          >
        </v-card-text>
        <v-card-actions class="justify-space-around">
          <v-btn
            v-if="currentUser"
            color="red darken-1"
            text
            @click="sendReport"
            >Отправить</v-btn
          >

          <v-btn color="green darken-1" text @click="dialogReport = false"
            >Закрыть</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: ["table", "table_type"],
  data: () => ({
    valid: true,

    dialogReport: false,

    reportType: 1,
    reportMsg: "",
    reportMsgRules: [
      (v) => !!v || "Поле обязательно",
      (v) => !!v === v.length >= 10 || "Минимум 10 символов",
    ],
  }),

  methods: {
    validate() {
      return this.$refs.formReport.validate();
    },
    sendReport() {
      // console.log("report", this.validate());
      if (!this.validate()) return;

      let app = this;
      this.$store
        .dispatch("addReport", {
          body: app.reportMsg,
          reporttable_id: app.table.id,
          reporttable_type: app.table_type,
          report_type: app.reportType,
        })
        .then((response) => {
          if (response.data.success == 1) {
            app.reportMsg = "";
            app.reportType = 1;
            app.dialogReport = false;
          }
        })
        .catch(() => {});
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      siteSettings: "siteSettings",
    }),
    reportTypes() {
      if (
        this.siteSettings &&
        this.siteSettings.report_types &&
        this.siteSettings.report_types.length > 0
      ) {
        return this.siteSettings.report_types;
      }
      return null;
    },
  },
  watch: {},
  mounted() {},
};
</script>
